<template>
  <div class="container">
    <van-row>
      <van-col span="24" class="manager-plate" @click="getManager(1)">
        <img class="manager-back" src="../../../assets/follow_bac1.png" alt />
        <div class="manager-content">
          <img
            class="manager-icon"
            src="../../../assets/manager_icon2.png"
            alt
          />
          <div class="manager-text">新增客户</div>
          <span></span>
        </div>
      </van-col>
    </van-row>
    <van-row>
      <van-col span="24" class="manager-plate" @click="getManager(2)">
        <img class="manager-back" src="../../../assets/follow_bac2.png" alt />
        <div class="manager-content">
          <img
            class="manager-icon"
            src="../../../assets/manager_icon4.png"
            alt
          />
          <div class="manager-text">我的跟进</div>
          <span></span>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 点击页面跳转
    getManager(index) {
      if (index === 1) {
        this.$router.push({ path: "/addCustomer", query: { follow: 1 } });
      } else if (index === 2) {
        this.$router.push({ path: "/myFollow" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  /deep/.van-row {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
  }
  .manager-plate {
    height: 120px;
    border-radius: 10px;
    margin: 10px;
    position: relative;
    .manager-back {
      position: absolute;
      top: 0;
      right: 0;
      height: 120px;
    }
    .manager-content {
      height: 120px;
      line-height: 120px;
      display: flex;
      .manager-icon {
        margin: 32px 65px 15px 30px;
        width: 50px;
        height: 50px;
      }
      .manager-text {
        position: absolute;
        right: 25%;
        padding-left: 16px;
        color: #fff;
        font-size: 18px;
      }
      span {
        width: 10px;
        height: 10px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        transform: rotate(45deg);
        position: absolute;
        top: 46%;
        right: 0px;
        position: absolute;
        right: 15%;
      }
    }
  }
  .van-row:nth-child(1) .manager-plate:nth-child(1) {
    background: linear-gradient(-17deg, #00d0d0 0%, #8bf7cc 100%);
    box-shadow: 0px 5px 15px 0px rgba(20, 213, 208, 0.41);
  }
  .van-row:nth-child(2) .manager-plate:nth-child(1) {
    background: linear-gradient(-17deg, #3480ff 0%, #00c4ff 100%);
    box-shadow: 0px 5px 15px 0px rgba(42, 141, 255, 0.41);
  }
}
</style>
